import React from 'react'
import './App.css'
// import Login from './Pages/Login/Login'
// import Layout from './components/Layout/Layout'
// import Dashboard from './Pages/Dashboard/Dashboard'
// import Medications from './Pages/Medications/Medications'
// import Labs from './Pages/Labs/Labs'
// import Messages from './Pages/Messages/Messages'
// import { Route, Routes } from "react-router-dom"
// import { ConfigProvider , theme } from 'antd'
import { UserProvider } from './context/UserContext'

// const {darkAlgorithm} = theme
import Routes from './Routes/Index'

const App = () => {
  return (
    <React.Fragment>
      <UserProvider>
       <Routes/>
       </UserProvider>
    </React.Fragment>
  )
}

export default App