import React, { useEffect, useState } from "react";
import { Table, Typography, Button, Modal } from "antd";
import { dateFormat, formatTime } from "../../utils/Utils";

const Medications = () => {
  const [userData, setUserData] = useState(null);
  const [viewData, setViewData] = useState(null);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const userDataString = sessionStorage.getItem('userData');
    if (userDataString) {
      const userDataObject = JSON.parse(userDataString);
      if (userDataObject.prescriptions) {
        userDataObject.prescriptions.sort((a, b) => new Date(b.date_time) - new Date(a.date_time));
      }
      setUserData(userDataObject);
    }
  }, []);
  const coloumns = [
    {
      title:'#',
      key:'#',
      render:(_, __, index)=> index + 1
    },
    {
      title:'ID',
      key:'id',
      dataIndex:'prescription_code'
    },
    {
      title: "Medication",
      dataIndex: "prescriptions",
      key: "prescriptions",
      render: (prescriptions) => (
        <span>
          {prescriptions.length <= 3 ? (
            prescriptions.map((prescription, index) => (
              <span
                key={prescription.key}
                title={prescription?.searched_medicine}
              >
                {index > 0 ? ", " : ""}
                {prescription?.searched_medicine?.length > 100
                  ? prescription?.searched_medicine?.slice(0, 100) + "..."
                  : prescription?.searched_medicine}
              </span>
            ))
          ) : (
            <>
              <span>
                {prescriptions[0]?.searched_medicine?.length > 100
                  ? prescriptions[0]?.searched_medicine?.slice(0, 100) + "..."
                  : prescriptions[0]?.searched_medicine}
                ,{" "}
              </span>
            </>
          )}
        </span>
      ),
    },
    {
      title: "Provider",
      dataIndex: "added_by",
      key: "added_by",
      align: "center",
      render: (text) => <span title={text}>{text}</span>,
    },
    {
      title: "Date",
      dataIndex: "date_time",
      key: "date_time",
      align:'center',
      sorter: (a, b) => {
        const dateA = new Date(a.date_time);
        const dateB = new Date(b.date_time);
        return dateA - dateB;
      },
      render: (text) => (
        <>
          <p>{dateFormat(text)}</p>
        </>
      ),
    },


    {
      dataIndex: "action",
      key: "action",
      title: "Action",
      align:'center',
      render: (text, record) => (
        <Button type="primary" size="small" onClick={() => handleView(record)}>
          View Prescriptions
        </Button>
      ),
    },
  ];

  const handleView = (record) => {
    setViewData(record);
    setVisible(true);
    console.log(record);
  };

  const onCancel = () => {
    setVisible(false);
  };

  return (
    <>
      <Typography.Title level={4} className="mb-3">
        Prescriptions
      </Typography.Title>
      <Table
        size="small"
        dataSource={userData?.prescriptions || []}
        columns={coloumns}
        pagination={false}
      />

      <Modal
        title={viewData?.prescription_code}
        open={visible}
        footer={null}
        onCancel={onCancel}
        maskClosable={false}
        width={650}
        closeIcon={
          <Button size="small" className="app-close-icon" shape="round">Close</Button>
        }
      >
        <div>
          <p>
            <span className="font-medium">Added By: </span>
            {viewData?.added_by}
          </p>
          <p>
            <span className="font-medium">Dated Added: </span>
            {dateFormat(viewData?.date_time)}, {formatTime(viewData?.date_time)}
          </p>

          {viewData?.prescriptions?.map((item, index) => (
            <div className="mt-3 bg-slate-100 p-3" key={index}>
              <div>
                <p>
                  <span className="font-medium"> Medicine Name: </span>
                  <span key={item?.key}>{item?.searched_medicine}</span>
                </p>
                <p>
                  <span className="font-medium"> Instructions: </span>
                  <span key={item?.key}>{item?.instructions}</span>
                </p>
                <p>
                  <span className="font-medium"> Quantity: </span>
                  <span key={item?.key}>{item?.dispense_quantity}</span>
                </p>
                <p>
                  <span className="font-medium"> Refills: </span>
                  <span key={item?.key}>{item?.refills}</span>
                </p>
                <p>
                  <span className="font-medium"> Dispense as Written: </span>
                  <span key={item?.key}>
                    {item?.dispense_as_written?.toString() === "true"
                      ? "Yes"
                      : "No"}
                  </span>
                </p>
                <p>
                  <span className="font-medium">Start Date: </span>
                  {item?.start_date ? dateFormat(item?.start_date) : "No Date Entered"}
                </p>
                <p>
                  <span className="font-medium">End Date: </span>
                  {item?.end_date ? dateFormat(item?.end_date) : "No Date Entered"}
                </p>
              </div>
            </div>
          ))}
        </div>
      </Modal>
    </>
  );
};

export default Medications;
