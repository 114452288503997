import { DateTime } from "luxon";
let locale = "en-US";

export const dateFormat = (date_time) => {

    const dateObj = new Date(date_time);
  
    const localDate = dateObj.toLocaleDateString(locale, {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
  
    return localDate;
  };



export const formatTime = (date, officeTimeFormat, officeTimezone) => {
  return  DateTime.fromISO(date, { zone: 'UTC' }).setZone(officeTimezone).toLocaleString({ hour: '2-digit', minute: '2-digit', hourCycle: officeTimeFormat });
};



export const formatDate = (date, format, officeTimezone)=>{
  return DateTime.fromISO(date, { zone: 'UTC' }).setZone(officeTimezone).setLocale(format).toLocaleString()
}

