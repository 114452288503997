import React from "react";
import ReactApexChart from "react-apexcharts";
import dayjs from "dayjs";
import { Empty } from "antd";

const ViewGraph = ({ data, title }) => {
  if (!data || data?.length === 0) {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  }

  const formattedData = data
    .map((item) => ({
      ...item,
      date_time: dayjs(item.date_time)?.format("DD-MM-YYYY HH:mm"),
      timestamp: new Date(item.date_time),
    }))
    .sort((a, b) => a.timestamp - b.timestamp);

  const mapToChartData = (property) => {
    return formattedData.map((item) => ({
      x: item.timestamp.getTime(),
      y: parseFloat(item[property]),
      category: property,
    }));
  };

  let series;
  if (title === "Body Fat Percentage") {
    const Height = mapToChartData("bodyfat_height");
    const Weight = mapToChartData("bodyfat_weight");
    const BodyFat = mapToChartData("bodyfat");

    series = [
      { name: "Height", data: Height },
      { name: "Weight", data: Weight },
      { name: "Body Fat Percentage", data: BodyFat },
    ];
  } else if (title === "BMI") {
    const Height = mapToChartData("BMI_height");
    const Weight = mapToChartData("BMI_weight");
    const BMI = mapToChartData("BMI");

    series = [
      { name: "Height", data: Height },
      { name: "Weight", data: Weight },
      { name: "BMI", data: BMI },
    ];
  } else if(title === "Basic Vitals"){
    const HeartRate = mapToChartData("heart_rate");
    const O2Saturation = mapToChartData("oxygen");
    const BodyTemperature = mapToChartData("body_temp");
    const RespiratoryRate = mapToChartData("resp_rate");
    const PainScale = mapToChartData("pain_scale");
    const HeightRate = mapToChartData("height");
    const WeightRate = mapToChartData("weight");

     series = [
      { name: "Heart Rate", data: HeartRate},
      { name: "O2 Saturation", data: O2Saturation},
      { name: "Height", data: HeightRate },
      { name: "Weight", data: WeightRate },
      { name: "Body Temperature", data: BodyTemperature},
      { name: "Respiratory Rate", data: RespiratoryRate},
      { name: "Pain Scale", data: PainScale},

     ]
  } else if(title === "Ideal Weight"){
    const Height = mapToChartData("idealweight_height");
    const IdealWeight = mapToChartData("idealweight");

    series = [
      {name: 'Height', data: Height},
      {name: 'Ideal Weight', data: IdealWeight},
    ]
  }

  const options = {
    chart: {
      height: 400,
      type: "line",
    },
    xaxis: {
      type: "datetime",
      labels: {
        formatter: (val) => dayjs(val).format("DD-MM-YYYY HH:mm"),
      },
    },
    dataLabels: {
      enabled: true,
    },
    yaxis: {
      labels: {
        formatter: (val) => val?.toFixed(2),
      },
    },
    legend: {
      position: "bottom",
    },
    markers: { style: "inverted", size: 6 },
    responsive: [
      {
        breakpoint: 600,
        options: { legend: { show: false } },
      },
    ],
  };

  return <ReactApexChart options={options} series={series} type="line" height={400} />;
};

export default ViewGraph;
