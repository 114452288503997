import React from 'react'
import {Table, Modal,  Tabs, Button} from 'antd'
import ViewGraph from '../../Pages/VitalSigns/components/ViewGraph';

const {TabPane} = Tabs
const TABLE_WIDTH = 800;

const CustomModal = ({ title, open, onCancel, data, columns, loading }) => {
    return (
      <Modal
        title={title}
        open={open}
        onCancel={onCancel}
        maskClosable={false}
        footer={null}
        width={TABLE_WIDTH}
        closeIcon={
          <Button size="small" className="app-close-icon" shape="round">Close</Button>
        }
      >
        <Tabs defaultActiveKey="1">
          <TabPane tab="Table" key="1">
            <Table
              loading={loading}
              size="small"
              dataSource={data}
              columns={columns}
              scroll={{ x: TABLE_WIDTH }}
              pagination={false}
            />
          </TabPane>
          <TabPane tab="Graph" key="2">
             <ViewGraph data={data} loading={loading} title={title}/>
          </TabPane>
        </Tabs>
      </Modal>
    );
  };

  export default CustomModal