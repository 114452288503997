import { Card } from "antd";
import React, { useEffect, useState } from "react";
import AllergiesModal from "./components/AllergiesModal";

const RecordedAllergies = () => {
    const [userData, setUserData] = useState(null);
    const [showAllergiesModal, setShowAllergiesModal] = useState({});

    const handleAllergyModal = (record) => {
        setShowAllergiesModal({
          open: true,
          selectedItem: record,
        });
      };
    
      const handleCancel = () => {
        setShowAllergiesModal(null);
      };

    const drugAllergies = userData?.allergies?.filter((item) => item?.drug);
    const foodAllergies = userData?.allergies?.filter((item) => item?.food);
    const environmentAllergies = userData?.allergies?.filter(
      (item) => item?.environment
    );
    const otherAllergies = userData?.allergies?.filter((item) => item?.other);
  
    const allergyList = [
      { type: "drug", allergies: drugAllergies },
      { type: "food", allergies: foodAllergies },
      { type: "environment", allergies: environmentAllergies },
      { type: "other", allergies: otherAllergies },
    ];


    useEffect(() => {
        const userDataString = sessionStorage.getItem("userData");
        if (userDataString) {
          const userDataObject = JSON.parse(userDataString);
          setUserData(userDataObject);
        }
      }, []);


  return (
    <>
      <Card className="shadow-lg rounded-2xl">
        {allergyList?.map(({ type, allergies }) => (
          <div key={type}>
            <p className="font-medium">{`${
              type.charAt(0).toUpperCase() + type.slice(1)
            } Allergies:`}</p>
            <ul>
              {allergies?.map((allergy) => (
                <li
                  key={allergy?.id}
                  className="text-[#1890ff] cursor-pointer flex items-center"
                >
                  <span
                    onClick={() => {
                      handleAllergyModal(allergy);
                    }}
                  >
                    {allergy?.allergy_value}
                  </span>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </Card>

      <AllergiesModal
        open={showAllergiesModal?.open}
        selectedItem={showAllergiesModal?.selectedItem}
        onClose={handleCancel}
      />
    </>
  );
};

export default RecordedAllergies;
