
import { Card, Typography } from "antd";
import { useEffect, useState } from "react";


const { Paragraph } = Typography;

const Dashboard = () => {
  const [userData, setUserData] = useState(null);
  useEffect(() => {
    const userDataString = sessionStorage.getItem('userData');
    if (userDataString) {
      const userDataObject = JSON.parse(userDataString);
      setUserData(userDataObject);
    }
  }, []);

  return (
    <div className="bg-gray-100 min-h-[900px] flex justify-center items-start rounded-xl">
      <div className="w-full  p-6 rounded-2xl">
        <h1 className="text-2xl font-semibold text-center bg-blue-100 p-3 mb-6 rounded-2xl">
          Welcome to {userData && userData?.clinic_name} Patient Portal
        </h1>

        <Card className="p-4 rounded-2xl">
          <Paragraph className="text-lg mb-4">
            Welcome to your personal patient portal. Here, you'll access all your
            medical reports and receive updates on basic healthcare vitals from
            your provider.
          </Paragraph>
          <Paragraph className="text-lg mb-4">
            Take advantage of this secure online service provided by your
            healthcare provider, with the assurance that your personal information
            is encrypted and stored securely.
          </Paragraph>
          <Paragraph className="text-lg mb-4 text-red-500">
          Remember! In Case of Life Threating Emergency always call your local emergency authorities, example 911 in
            U.S
          </Paragraph>
          <Paragraph className="text-lg mb-4">
            Please find below additional helpful information.
          </Paragraph>

          <ul className="list-disc ml-6">
            <li className="text-lg mb-2">
              To view the latest updates on your basic vitals, simply click on
              the "Basic Vitals" tab.
            </li>
            <li className="text-lg mb-2">
              To view your most recent prescription, click on the "Prescription"
              tab on the dashboard.
            </li>
            <li className="text-lg mb-2">
              Click on "Ordered Labs" to view all your requested lab tests.
            </li>
            <li className="text-lg mb-2">
              To access your recent lab results, click on the "Lab Results" tab
              on the dashboard.
            </li>
          </ul>
        </Card>
      </div>
    </div>
  );
};

export default Dashboard;
