import React from 'react'
import Dashboard from '../Pages/Dashboard/Dashboard'
import Labs from '../Pages/Labs/Labs'
import Medications from '../Pages/Medications/Medications'
import Messages from '../Pages/Messages/Messages'

import Login from '../Pages/Login/Login'
import LabResults from '../Pages/Labs/LabResults'
import VitalSigns from '../Pages/VitalSigns/VitalSigns'


const authProtectedRoutes = [
    {path: "/dashboard" , component : <Dashboard/>},
    {path: "/ordered-labs" , component : <Labs/>},
    {path: "/vital-signs" , component : <VitalSigns/>},
    {path: "/lab-results" , component : <LabResults/>},
    {path: "/medications" , component : <Medications/>},
    {path: "/providers-comments" , component : <Messages/>}
]

const publicRoutes = [
    {path: "/" , component: <Login/>}
]

export { authProtectedRoutes, publicRoutes };