import { Button, Card, Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { formatDate, formatTime } from "../../utils/Utils";
import CustomModal from "../../components/CustomModal/CustomModal";

const vitalsColumn = [
  {
    title: "Date",
    dataIndex: "date_time",
    key: "date_time",
    render: (text) => (
      <>
        <p>{formatDate(text)}</p>
        <p>{formatTime(text)}</p>
      </>
    ),
  },
  {
    title: "Blood Pressure",
    dataIndex: "blood_pressure",
    key: "blood_pressure",
    align: "center",
  },
  {
    title:"Height",
     align: 'center',
    dataIndex:"height",
    key:"height"
  },
  {
    title:"Weight",
     align: 'center',
    dataIndex:'weight' ,
    key:'weight'
  },
  {
    title: "Heart Rate",
    dataIndex: "heart_rate",
    key: "heart_rate",
    align: "center",
  },
  {
    title: "O2 Saturation",
    dataIndex: "oxygen",
    key: "oxygen",
    align: "center",
    render: (oxygen)=> `${oxygen ?`${oxygen}%`: ""}`
  },
  {
    title: "Body Temperature",
    dataIndex: "body_temp",
    key: "body_temp",
    align: "center",
    render: (temperature) => {
      return temperature >= 100 && temperature <= 110 ? (
        <p className="text-red-500">{temperature} F</p>
      ) : temperature >= 90 && temperature <= 100 ? (
        <p className="text-green-500">{temperature} F</p>
      ) : temperature >= 30 && temperature < 40 ? (
        <p className="text-green-500">{temperature} C </p>
      ) : temperature >= 40 && temperature <= 45 ? (
        <p className="text-red-500">{temperature} C </p>
      ) : (
        <p>{temperature}</p>
      );
    },
  },
  {
    title: "Respiratory Rate",
    dataIndex: "resp_rate",
    key: "resp_rate",
    align: "center",
  },
  {
    title: "Pain Scale",
    dataIndex: "pain_scale",
    key: "pain_scale",
    align: "center",
  },
  {
    title: "Added By",
    dataIndex: "added_by",
    key: "added_by",
  },
];

const bmiColumn = [
    {
      title: "Date",
      dataIndex: "date_time",
      key: "date_time",
      render : (text) => 
      <><p>{formatDate(text)}
      </p> 
      <p>
        {
          formatTime(text)
        }
      </p>
      </>
    },
    {
      title: "Height",
      dataIndex: "BMI_height",
      key: "BMI_height",
      align: 'center'
    },
    {
      title: "Weight",
      dataIndex: "BMI_weight",
      key: "BMI_weight",
      align: 'center'
    },
    {
      title: "BMI",
      dataIndex: "BMI",
      key: "BMI",
      align: 'center',
      render : (text) => {
        return(
          text < 18.5 
          ? <p className="text-red-300">{text }</p>
          :text >= 18.5 && text <=24.9
          ?  <p className="text-green-500">{text }</p>
          : text >= 25 && text <=29.9 
          ? <p className="text-yellow-500">{text }</p>
          : text >= 30 
          ?  <p className="text-red-300">{text }</p>
          : <p>{text }</p>
        )
      }
    },
    {
      title: "BMI Category",
      dataIndex: "BMI",
      key: "BMI",
      align: 'center',
      render : (text) => {
        return(
          text < 18.5 
          ? <p className="text-red-300"> Underweight </p>
          :text >= 18.5 && text <=24.9
          ?  <p className="text-green-500">Normal Weight </p>
          : text >= 25 && text <=29.9 
          ? <p className="text-yellow-500">Overweight</p>
          : text >= 30 
          ?  <p className="text-red-300">Obesity</p>
          : <p>{text }</p>
        )
      }
    },
    {
      title: 'Added By',
      dataIndex: 'added_by',
      key: 'added_by'
    }
  ]
  
  const idealWeightColumn = [
    {
      title: "Date",
      dataIndex: "date_time",
      key: "date_time",
      render : (text) => 
      <><p>{formatDate(text)
      }
      </p> 
      <p>
        {
          formatTime(text)
        }
      </p>
      </>
    },
  
    {
      title: "Height",
      dataIndex: "idealweight_height",
      key: "idealweight_height",
      align:'center'
    },
    {
    title: 'Gender',
    dataIndex: 'idealweight_gender',
    key:'idealweight_gender',
    align:'center'
    },
    {
      title: "Ideal Weight",
      dataIndex: "idealweight",
      key: "idealweight",
      align:'center'
    },
    {
      title: 'Added By',
      dataIndex: 'added_by',
      key: 'added_by'
    }
  ]
  
  const bodyFatColumn = [
    {
      title: "Date",
      dataIndex: "date_time",
      key: "date_time",
      render : (text) => 
      <><p>{formatDate(text)
      }
      </p> 
      <p>
        {
          formatTime(text)
        }
      </p>
      </>
    },
    {
      title: "Age",
      dataIndex: "bodyfat_waist",
      key: "bodyfat_waist",
    },
    {
      title: 'Gender',
      dataIndex: 'bodyfat_neck',
      key: 'bodyfat_neck'
    },
    {
      title: "Height",
      dataIndex: "bodyfat_height",
      key: "bodyfat_height",
      align: 'center'
    },
    {
      title: "Weight",
      dataIndex: "bodyfat_weight",
      key: "bodyfat_weight",
      align: 'center'
    },
    {
      title: 'Body Fat Percentage',
      dataIndex: 'bodyfat',
      key: 'bodyfat',
      align: 'center'
    },
    {
      title: 'Added By',
      dataIndex: 'added_by',
      key: 'added_by'
    }
  ]

const RecordedVitals = () => {
  const [userData, setUserData] = useState(null);
  const [isBasicModalVisible, setIsBasicModalVisible] = useState({});
  const [isBodyFatModalVisible, setIsBodyFatModalVisible] = useState({});
  const [isBMIModalVisible, setIsBMIModalVisible] = useState({})
  const [isIdealWeight, setIsIdealWeight] = useState({})

  const handleBasicVitals = (record) => {
    setIsBasicModalVisible({
      show: true,
      data: record,
    });
  };

  const handleBodyFat = (record) => {
    setIsBodyFatModalVisible({
      show: true,
      data: record,
    });
  };

  const handleBMI = (record) => {
    setIsBMIModalVisible({
      show: true,
      data: record,
    });
  };

  const handleIdealWeight = (record) => {
    setIsIdealWeight({
      show: true,
      data: record,
    });
  };

  // useEffect(() => {
  //   const userDataString = sessionStorage.getItem("userData");
  //   if (userDataString) {
  //     const userDataObject = JSON.parse(userDataString);
  //     setUserData(userDataObject);
  //   }
  // }, []);
  useEffect(() => {
    const userDataString = sessionStorage.getItem("userData");
    if (userDataString) {
      const userDataObject = JSON.parse(userDataString);

      // Sort the data in descending order by date_time
      for (const key in userDataObject) {
        if (Array.isArray(userDataObject[key])) {
          userDataObject[key].sort((a, b) => {
            const dateA = new Date(a.date_time);
            const dateB = new Date(b.date_time);
            return dateB - dateA; // Descending order
          });
        }
      }

      setUserData(userDataObject);
    }
  }, []);

  return (
    <>
      <Card className="shadow-lg rounded-2xl">
        <Row gutter={[16, 16]}>
          <Col sm={24} md={12} lg={12}>
            <Button
              type="primary"
              size="large"
              shape="round"
              onClick={() => handleBasicVitals(userData?.vitals)}
            >
              View Basic Vitals
            </Button>
          </Col>
          <Col sm={24} md={12} lg={12}>
            <Button
              type="primary"
              size="large"
              shape="round"
              onClick={() => handleBodyFat(userData?.body_fat)}
            >
              View Body Fat
            </Button>
          </Col>
        </Row>
        <Row gutter={[16, 16]} className="mt-4">
          <Col sm={24} md={12} lg={12}>
            <Button type="primary" size="large" shape="round"
             onClick={() => handleBMI(userData?.bmi)}
            >
              View BMI
            </Button>
          </Col>
          <Col sm={24} md={12} lg={12}>
            <Button type="primary" size="large" shape="round"
            onClick={() => handleIdealWeight(userData?.ideal_weight)}
            >
              View Ideal Weight
            </Button>
          </Col>
        </Row>
      </Card>

      <CustomModal
        title="Basic Vitals"
        open={isBasicModalVisible?.show}
        onCancel={() => setIsBasicModalVisible(null)}
        data={isBasicModalVisible?.data}
        columns={vitalsColumn}
      />

      <CustomModal
        title="Body Fat Percentage"
        open={isBodyFatModalVisible?.show}
        onCancel={() => setIsBodyFatModalVisible(null)}
        data={isBodyFatModalVisible?.data}
        columns={bodyFatColumn}
      />

      <CustomModal
        title="BMI"
        open={isBMIModalVisible?.show}
        onCancel={() => setIsBMIModalVisible(null)}
        data={isBMIModalVisible?.data}
        columns={bmiColumn}
      />

      <CustomModal
        title="Ideal Weight"
        open={isIdealWeight?.show}
        onCancel={() => setIsIdealWeight(null)}
        data={isIdealWeight?.data}
        columns={idealWeightColumn}
      />
    </>
  );
};

export default RecordedVitals;
