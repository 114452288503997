import React, { useState, useEffect } from 'react';
import { List, Avatar, Modal, Typography, Button, Input, Card } from 'antd';
import { dateFormat, formatDate, formatTime } from '../../utils/Utils';
// import dayjs from 'dayjs';

const Messages = () => {
  const [userData, setUserData] = useState(null);
  const [showMessage, setShowMessage] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});

  // const [highlighted, setHighlighted] = useState(false);

  // useEffect(() => {
  //   const differenceInDays = dayjs().diff(dayjs(item.date_time), 'day');

  //   if (differenceInDays < 7) {
  //     const interval = setInterval(() => {
  //       setHighlighted((prev) => !prev);
  //     }, 500); // Blink interval, you can adjust this value

  //     return () => clearInterval(interval);
  //   }
  // }, [item.date_time]);

  useEffect(() => {
    const userDataString = sessionStorage.getItem('userData');
    if (userDataString) {
      const userDataObject = JSON.parse(userDataString);
      setUserData(userDataObject);
    }
  }, []);

  const openMessage = (item) => {
    setSelectedItem(item);
    setShowMessage(true);
  };

  const onCancel = () => {
    setShowMessage(false);
  };

  const renderListItem = (item, index) => (
    <List.Item key={index} onClick={() => openMessage(item)} 
    className={`cursor-pointer`}
    >
      <List.Item.Meta
        className="mr-4"
        avatar={
          <Avatar
          style={{
            backgroundColor: '#f56a00',
          }}
        >
          {item && item?.created_by.charAt(0).toUpperCase()}
        </Avatar>
        }
        title={
          <p>
            <span>Subject: {item && item?.subject} </span>
          </p>
        }
        description={
          <p>
            <span className="text-gray-400 font-thin">Added By: {item && item?.created_by} </span>
            <span className="mr-2 text-gray-400 font-thin"> - {item && formatDate(item?.date_time)}, {item && formatTime(item?.date_time)} </span>
          </p>
        }
      />
    </List.Item>
  );

  return (
    <>
    <div className='flex justify-between'>
      <Typography.Title level={4} className="mb-3">
        Provider's Comments
      </Typography.Title>

      </div>
      <List
        className="max-h-[500px] overflow-auto mt-4"
        itemLayout="horizontal"
        dataSource={userData && userData?.patient_messaging.slice().reverse() || []} 
        renderItem={renderListItem}
      />

      <div className="flex flex-col items-center mt-10 text-black dark:text-white text-lg">
        <Typography.Paragraph className='text-lg'>
          This is a one-way communication from Provider
        </Typography.Paragraph>
        <Typography.Paragraph className='text-lg'>
          Call your office for further information and to book an appointment
        </Typography.Paragraph>
        <p className="text-red-600 font-semibold">
          In Case of an Emergency or life-threatening situation always call your local emergency
          authorities example 911 in U.S
        </p>
      </div>

      <Modal
        title="Message"
        open={showMessage}
        footer={null}
        onCancel={onCancel}
        closeIcon={
          <Button size="small" className="app-close-icon" shape="round">Close</Button>
        }
      >
        <Typography.Paragraph>
          <span className="font-medium">Provider: </span> {selectedItem?.created_by}
        </Typography.Paragraph>
        <Typography.Paragraph>
          <span className="font-medium">Date: </span> {formatDate(selectedItem?.date_time)}, {formatTime(selectedItem?.date_time)}
        </Typography.Paragraph>
        <Typography.Paragraph>
          <span className="font-medium">Subject: </span>
          {selectedItem?.subject}
        </Typography.Paragraph>
        <Typography.Paragraph>
          <span className="font-medium">Provider's Comment: </span>
          {selectedItem?.message}
        </Typography.Paragraph>
      </Modal>
    </>
  );
};

export default Messages;
