export const validatePassword = (_, value) => {
    if (value) {
      if (
        !/(?=.*[A-Z])/.test(value) ||
        !/(?=.*\d)/.test(value) ||
        !/(?=.*[!@#$%^&*])/.test(value)
      ) {
        return Promise.reject(
          "Password must contain at least one uppercase letter, one number, and one special character (!@#$%^&*)"
        );
      }
    }
    return Promise.resolve();
  };