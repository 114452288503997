import { message } from 'antd';
import axios from 'axios';
import { createContext, useContext, useState } from 'react';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [loading, setLoading] = useState(true)
  const [userData, setUserData] = useState(null);
  const [appLogoData, setAppLogoData] = useState(null)
  const API_Path = process.env.REACT_APP_API_URL || 'http://localhost:8000';

  const setUser = (data) => {
    setUserData(data);
  };

  const getAppLogo = async () => {
    try {
      const response = await axios.get(`${API_Path}/administration/retrieve-application-images/`);
      if (response.status === 200) {
        setAppLogoData(response.data.data);
      } else if (response.status === 400){
        setAppLogoData(null)
      }
    } catch (error) {
      message.error("Something went wrong")
    } finally {
      setLoading(false);
    }
  };



  return (
    <UserContext.Provider value={{ userData, setUser, appLogoData, getAppLogo }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};
