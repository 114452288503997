import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Button,
  message,
  Space,
  Typography,
} from "antd";
import logo from "../../Assets/logo.png";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import { useUser } from "../../context/UserContext";
import "./login.css"; // Import your custom CSS file
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";

const { Title, Paragraph } = Typography;

const Login = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { appLogoData, getAppLogo } = useUser();
  const [loading, setLoading] = useState(false);
  const API_Path = process.env.REACT_APP_API_URL || 'http://localhost:8000';

  useEffect(() => {
    getAppLogo();
  }, []);

  const handleLogin = async (values) => {
    try {
      setLoading(true);

      const response = await axios.post(
        `${API_Path}/patient/login/`,
        values
      );
      if (response.status === 200) {
        sessionStorage.setItem("userData", JSON.stringify(response.data));
        message.success("Login successful!");
        navigate("/dashboard");
      } else {
        message.error("Login failed. Please check your credentials.");
      }
    } catch (error) {
      message.error("An error occurred during login.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="login-container">
      <div className="login-content">
        <img
          src={appLogoData?.icon || logo}
          alt="American EMR Logo"
          className="logo"
        />
        <Title level={2} style={{ color: "#64748b" }}>
          Patient Portal Login
        </Title>
        <Paragraph>Login with your username and password.</Paragraph>

        <Form form={form} onFinish={handleLogin} layout="vertical">
          <Form.Item
            name="mrn_number"
            rules={[{ required: true, message: "Please input your username!" }]}
            label="MRN Number"
          >
            <Input placeholder="AB-1-AB-12345" />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[{ required: true, message: "Please input your password!" }]}
            label="Password"
          >
            <Input.Password
              iconRender={(visible) =>
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
              }
            />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" block>
              Login
            </Button>
          </Form.Item>
        </Form>

        <Space direction="vertical" size="small" style={{ display: "flex" }}>
          <Link
            href="#"
            title="If you forget your password, please call your Provider's office or clinic to recover your login credentials."
          >
            Forgot Password?
          </Link>

          <Paragraph type="secondary" className="terms-privacy">
            <a href="https://www.americanemr.com/terms--conditions.html" target="_blank" rel="noopener noreferrer">
              Terms & Conditions
            </a>{" "}
            and{" "}
            <a href="https://www.americanemr.com/privacy-policy.html" target="_blank" rel="noopener noreferrer">
              Privacy Policy
            </a>
          </Paragraph>
        </Space>

        <div className="social-icons">
          {/* Add your social media icons here (replace with actual links/icons) */}
        </div>
      </div>
    </div>
  );
};

export default Login;
