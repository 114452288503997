import { Button, Modal, Typography, Image, Space } from "antd";
import React from "react";
import { formatDate, formatTime } from "../../../utils/Utils";
import {
  RotateLeftOutlined,
  RotateRightOutlined,
  SwapOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
  FilePdfOutlined,
} from "@ant-design/icons";

const ViewLabResults = ({ visible, onClose, data, fileData, loading }) => {
  const showFile = (fileContent) => {
    window.open(fileContent, "_blank");
  };

  const renderFileContent = () => {
    const fileExtension = fileData?.type?.split("/").pop();
    if (fileExtension === "pdf") {
      return (
        <div className="pdf-container">
          <FilePdfOutlined style={{ fontSize: "64px", color: "#ff0000" }} />
          <Typography.Text strong className="pdf-label">
            PDF Document
          </Typography.Text>
        </div>
      );
    } else {
      return (
        <Image
          className="image-preview"
          src={fileData?.url}
          preview={{
            toolbarRender: (
              _,
              {
                transform: { scale },
                actions: {
                  onFlipY,
                  onFlipX,
                  onRotateLeft,
                  onRotateRight,
                  onZoomOut,
                  onZoomIn,
                },
              }
            ) => (
              <Space size={12} className="toolbar-wrapper">
                <SwapOutlined rotate={90} onClick={onFlipY} />
                <SwapOutlined onClick={onFlipX} />
                <RotateLeftOutlined onClick={onRotateLeft} />
                <RotateRightOutlined onClick={onRotateRight} />
                <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
              </Space>
            ),
          }}
        />
      );
    }
  };

  return (
    <Modal
      open={visible}
      onCancel={onClose}
      footer={null}
      width={650}
      title={data && data?.filename}
      confirmLoading={loading}
      className="lab-results-modal"
      closeIcon={
        <Button size="small" className="app-close-icon" shape="round">Close</Button>
      }
    >
      <div className="lab-details">
        <Typography.Paragraph>
          <Typography.Text strong>Uploaded By:</Typography.Text>{" "}
          {data?.added_by}
        </Typography.Paragraph>
        <Typography.Paragraph>
          <Typography.Text strong>Date Added:</Typography.Text>{" "}
          {formatDate(data?.date_time)}, {formatTime(data?.date_time)}
        </Typography.Paragraph>
        <Typography.Paragraph>
          <Typography.Text strong>Lab Order ID:</Typography.Text>{" "}
          {data?.lab_order_id}
        </Typography.Paragraph>
        <Typography.Paragraph>
          <Typography.Text strong>Service Name:</Typography.Text>{" "}
          {data?.service_name}
        </Typography.Paragraph>
        <Typography.Paragraph>
          <Typography.Text strong>Description:</Typography.Text>{" "}
          {data?.description}
        </Typography.Paragraph>
      </div>
      
      {renderFileContent()}

      <div className="view-file-button">
        <Button
          type="primary"
          onClick={() => showFile(fileData && fileData?.url)}
        >
          View File
        </Button>
      </div>
    </Modal>
  );
};

export default ViewLabResults;
