import React, { useState, useEffect } from "react";
import { Avatar, Button, Dropdown, Modal, Form, Input, message } from "antd";
import { LockOutlined, LogoutOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { validatePassword } from "../../utils/validator";
import axios from "axios";

const PatientProfile = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm()
  const [userData, setUserData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const API_Path = process.env.REACT_APP_API_URL || 'http://localhost:8000';

  const handleCancel = () => {
    setShowModal(false);
  };

  useEffect(() => {
    const userDataString = sessionStorage.getItem("userData");
    if (userDataString) {
      const userDataObject = JSON.parse(userDataString);
      setUserData(userDataObject);
    }
  }, []);

  const items = [
    {
      label: "Update Password",
      key: "0",
      icon: <LockOutlined />,
    },
    {
      label: "Logout",
      key: "1",
      icon: <LogoutOutlined />,
    },
  ];

  const onClick = async ({ key }) => {
    if (key === "0") {
      setShowModal(true);
    }
    if (key === "1") {
      sessionStorage.clear();
      navigate("/");
    }
  };

  const updatePassword = async (values)=>{
    const data = {
      password: values?.password
    }
    try{
      const response = await axios.patch(`${API_Path}/patient/update-patient-password/${userData?.patient_info?.id}/`, data)
      if(response.status === 200){
        message.success("Password Udpated Successfully")
        handleCancel()
      }
    }
    catch(error){
      message.error("Something Went Wrong. Contact the Clinic Management")
    }
  }

  return (
    <>
      <Dropdown
        trigger={["click"]}
        menu={{ items: items, onClick }}
        open={isDropdownVisible}
        onOpenChange={setDropdownVisible}
      >
        <div className="flex items-center px-3 py-2 rounded-full cursor-pointer mr-4">
          <div className="mr-2">
            <p className="text-sm font-semibold">
              {userData?.patient_info?.first_name}{" "}
              {userData?.patient_info?.middle_name &&
                userData?.patient_info?.middle_name}{" "}
              {userData?.patient_info?.last_name}
            </p>
            <p className="text-xs">Patient</p>
          </div>
          {/* <Avatar shape="circle">K</Avatar> */}
          <Avatar
            shape="circle"
            className="w-10 h-10 flex justify-center"
            src={userData?.patient_info?.profile_picture}
          >
            {!userData?.patient_info?.profile_picture &&
              userData?.patient_info?.first_name[0]}
          </Avatar>
        </div>
      </Dropdown>

      <Modal
        title="Update Password"
        open={showModal}
        footer={null}
        onCancel={handleCancel}
        closeIcon={
          <Button size="small" className="app-close-icon" shape="round">Close</Button>
        }
        width={376}
      >
        <Form layout="vertical" form={form} onFinish={updatePassword}>
          <Form.Item
            label="New Password"
            name="password"
            rules={[
              { required: true, message: "Please input your password!" },
              { validator: validatePassword },
            ]}
          >
            <Input.Password
              placeholder="Please enter your Password here"
              minLength={8}
            />
          </Form.Item>
          <Form.Item
            name="confirm_password"
            label="Confirm New Password"
            hasFeedback
            dependencies={["password"]}
            rules={[
              { required: true, message: "Please confirm your password!" },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject("Passwords do not match!");
                },
              }),
            ]}
          >
            <Input.Password placeholder="Confirm Password" />
          </Form.Item>

          <div className="flex justify-end">
            <Button>Cancel</Button>
            <Button type="primary" htmlType="submit" className="ml-2">
              Update
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default PatientProfile;
