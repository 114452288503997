import React from 'react'
import { authProtectedRoutes, publicRoutes } from "./routes";
import { Routes, Route } from "react-router-dom";
import NonAuthLayout from './NonAuthLayout';
import { AuthProtected } from "./AuthProtected";
import Layout from '../components/Layout/Layout'

const Index = () => {
  return (
    <Routes>
    <Route>
      {publicRoutes.map((route, idx) => (
        <Route
          path={route.path}
          element={
            <NonAuthLayout>
                {route.component}
            </NonAuthLayout>
        }
          key={idx}
          exact={true}
        />
      ))}
    </Route>

    <Route>
        {authProtectedRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={
              <AuthProtected>
                  <Layout>{route.component}</Layout>
              </AuthProtected>}
            key={idx}
            exact={true}
          />
        ))}
    </Route>
  </Routes>
  )
}

export default Index