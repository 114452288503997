import React, { useEffect, useState } from 'react';
import {
  MenuOutlined ,
  CommentOutlined,
  HomeOutlined
} from '@ant-design/icons';
import { Layout, Menu, Button, theme } from 'antd';
import Logo from '../../Assets/logo.png'
import LogoCollapse from '../../Assets/logo.svg'
import {  useNavigate, useLocation } from 'react-router-dom';
import PatientProfile from '../PatientProfile/PatientProfile';
import { FaFlaskVial } from "react-icons/fa6";
import { TbReportSearch } from "react-icons/tb";
import { FaHeartbeat } from "react-icons/fa";
import { FaStaffSnake } from "react-icons/fa6";
import { useUser } from '../../context/UserContext';
const { Header, Sider, Content } = Layout;

const items = [
  {
    key: '/dashboard',
    icon: <HomeOutlined />,
    label: 'Home',
  },
  {
    key:'/vital-signs',
    icon: <FaHeartbeat/>,
    label:'Basic Vitals'
  },
  {
    key: '/medications',
    icon: <FaStaffSnake />,
    label: 'Prescriptions',
  },
  {
    key: '/ordered-labs',
    icon: <FaFlaskVial />,
    label: 'Ordered Labs',
  },
  {
    key: '/lab-results',
    icon: <TbReportSearch />,
    label: 'Lab Results',
  },
  {
      key: '/providers-comments',
      icon: <CommentOutlined/>,
      label: "Provider's Comments",
    },
]

const App = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { appLogoData , getAppLogo} = useUser();
  const [collapsed, setCollapsed] = useState(false);

  
  useEffect(()=>{
    getAppLogo()
  },[])

  const {
    token: { colorBgContainer },
  } = theme.useToken();
  return (
    <Layout className='h-screen'>
      <Sider trigger={null} collapsible collapsed={collapsed} width={256}
       style={{
        position:'fixed',
        height:'100vh'
      }}
      >
      <div
          className="flex justify-center brand"
          style={{
            transition: "margin-left 0.2s",
          }}
        >
          {collapsed ? (
            <img src={LogoCollapse} alt="Logo" className="w-[2rem] my-5 logo" />
          ) : (
            <img src={appLogoData?.icon || Logo} alt="Logo" className="w-[10rem] my-5" />
          )}
        </div>
        <Menu
        
          theme="dark"
          mode="inline"
          className='mt-2'
          defaultSelectedKeys={['1']}
          selectedKeys={[location.pathname]}
          onClick={({ key }) => navigate(key)}
          items={items}
        />
      </Sider>
      <Layout>
        <Header
        className="flex justify-between shadow-sm"
          style={{
            padding: 0,
            background: colorBgContainer,
          }}
        >
          <Button
            type="text"
            icon={collapsed ? <MenuOutlined /> : <MenuOutlined  />}
            onClick={() => setCollapsed(!collapsed)}
            style={{
              fontSize: '16px',
              width: 64,
              height: 64,
            }}
          />
           <PatientProfile/>
        </Header>
        <Content
          style={{
            // margin: '24px 16px',
            marginLeft: collapsed ? "80px" : "256px",
            padding: 24,
            minHeight: 280,
            background: colorBgContainer,
          }}
        >
          {props.children}
        </Content>
      </Layout>
    </Layout>
  );
};
export default App;